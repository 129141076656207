// Import functions
import { generateTodayDate } from "../functions/generates.js";

// Import messages
import { ServicesMessages } from "../messages/servicesMessages.js";

export const validateScheduleFields = (name, value) => {
    const validators = {
        name: () => {
            if (!value) return ServicesMessages.REQUIRED_FIELDS.FULL_NAME;
            return '';
        },
        phone: () => {
            if (!value) return ServicesMessages.REQUIRED_FIELDS.PHONE;
            if (value.length < 14) return ServicesMessages.INVALID_FIELDS.PHONE
            return '';
        },
        typeOfService: () => {
            if (!value) return ServicesMessages.REQUIRED_FIELDS.TYPE_OF_SERVICE;
            return '';
        },
        dateOfService: () => {
            if (!value) return ServicesMessages.REQUIRED_FIELDS.DATE;
            if (value < generateTodayDate()) return ServicesMessages.INVALID_FIELDS.MIN_DATE;
            return '';
        },
        startTime: () => {
            if (!value) return ServicesMessages.REQUIRED_FIELDS.START_TIME
        }
    };

    return validators[name] ? validators[name]() : '';
};
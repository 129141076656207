// Import react
import React, { createContext, useState, useContext } from "react";

// Defines the context
const SchedulingContext = createContext();

export const SchedulingProvider = ({ children }) => {
    const [scheduling, setScheduling] = useState({
        services: [],
        customer: {
            name: '',
            phone: ''
        },
        steps: {
            current: 0,
            next: 0
        }
    });

    const [feedback, setFeedback] = useState({
        type: '',
        message: '',
        status: false
    });

    const closeFeedback = () => {
        setFeedback((prev) => ({
            ...prev,
            status: false
        }));
    };

    // Auxiliary function to add a service
    const addService = (newService) => {
        setScheduling((prev) => ({
            ...prev,
            services: [...prev.services, newService],
        }));
    };

    // Auxiliary function to remove a service
    const removeService = (serviceId) => {
        setScheduling((prev) => ({
            ...prev,
            services: prev.services.filter((service) => service.id !== serviceId)
        }));
    };

    // Auxiliary function to set next step
    const setNextStep = (event, nextStep) => {
        event.preventDefault();

        setScheduling((prev) => ({
            ...prev,
            steps: {
                ...prev.steps,
                current: nextStep
            }
        }));
    };

    return (
        <SchedulingContext.Provider value={{ scheduling, setScheduling, addService, removeService, setNextStep, feedback, setFeedback, closeFeedback }}>
            {children}
        </SchedulingContext.Provider>
    );
};

export const useSchedulingOptions = () => useContext(SchedulingContext);

// Import react
import { useState, useEffect } from 'react';

// Import services
import { getListService } from '../services/manageServices';

// Import messages
import { ServicesMessages } from '../messages/servicesMessages';

export const useListService = () => {
    const [ourServices, setOurServices] = useState({
        data: {
            types: [],
            services: [],
            length: ''
        },
        error: {
            status: false,
            message: ''
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const listService = await getListService()

                setOurServices({
                    data: {
                        types: listService.map(item => ({
                            type: item.type,
                            duration: item.duration
                        })),
                        services: listService,
                        length: (
                            listService.length > 0
                                ? `Foram encontrado(s) ${listService.length} serviço(s) cadastrados.`
                                : 'Não foram encontrados serviços cadastrados.'
                        )
                    }
                });
            } catch (error) {
                setOurServices({
                    error: {
                        status: true,
                        message: ServicesMessages.GENERIC.INTERNAL_ERROR
                    }
                })
            }
        };

        fetchData();
    }, []);

    return { ourServices };
};
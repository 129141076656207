// Import react
import React from 'react';

// Import dependencies
import { clsx } from 'clsx';

// Import CSS
import './ModalOfActionOrFeedback.scss'

// Import icons
import { FeedbackError, FeedbackSuccess } from '../../icons/Feedback';

const ModalOfActionOrFeedback = ({ conditionToBeVisible, typeOfModal, data, buttons }) => {
    // Defines the icons to be used
    const icons = (type) => {
        const iconMap = {
            error: <FeedbackError width="28px" height="28px" color="#FFF" />,
            success: <FeedbackSuccess width="28px" height="28px" color="#FFF" />
        };

        return iconMap[type] || null;
    };

    return (
        <>
            {conditionToBeVisible && (
                <div className="action-or-feedback">
                    <div className="content">
                        <div className={clsx("header", `background-${typeOfModal.colorFor}`)}>{icons(typeOfModal.colorFor)}</div>
                        <div className="data">
                            <div className="texts">
                                <h2 className={clsx("title", `color-${typeOfModal.colorFor}`)}>{data.title}</h2>
                                <h3 className="message">{data.message}</h3>
                            </div>

                            <div className="buttons">
                                {typeOfModal.type === "feedback" && (
                                    <button id="confirm" className={clsx("full-size", `background-${typeOfModal.colorFor}`)} onClick={buttons.onClickConfirm}> {buttons.confirm} </button>
                                )}

                                {typeOfModal.type === "action" && (
                                    <>
                                        <button id="cancel" onClick={buttons.onClickCancel}> {buttons.cancel} </button>
                                        <button id="confirm" className={clsx(`background-${typeOfModal.colorFor}`)} onClick={buttons.onClickConfirm}> {buttons.confirm} </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default ModalOfActionOrFeedback;
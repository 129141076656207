export const generateTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const generateEndTime = (startTime, duration) => {
    const [hours, minutes] = startTime.split(':').map(Number);

    const initialTime = new Date();
    initialTime.setHours(hours, minutes, 0, 0);

    const finalTime = new Date(initialTime.getTime() + duration * 60 * 1000);

    const finalHours = finalTime.getHours().toString().padStart(2, '0');
    const finalMinutes = finalTime.getMinutes().toString().padStart(2, '0');

    return `${finalHours}:${finalMinutes}`;
}
// Import React
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals.js';

// Import CSS
import './index.css';
import './styles/main.scss'

// Import APP
import App from './App.jsx';

// Config router
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

// Import Routes
import Home from './routes/Home.jsx';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Navigate to="/" />,
        children: [
            {
                path: "/",
                element: <Home />
            }
        ]
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </React.StrictMode>
);

reportWebVitals();
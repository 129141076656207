// Import react
import React from 'react';

// Import dependencies
import { clsx } from 'clsx';

// Import CSS
import './formField.scss'
import { generateTodayDate } from '../../functions/generates';

const FormField = ({
    inputId,
    inputCode,
    inputSize,
    inputType,
    inputLabel,
    inputName,
    inputValue,
    inputMaxLength,
    inputPlaceholder,
    inputOnChange,
    inputMaxSize,
    inputMinDate,
    selectOptions,
    feedbackIcon,
    feedbackType,
    feedbackMessage
}) => {
    const formatFieldValue = {
        0: (value) =>
            value
                .slice(0, 11)
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d{1,2})/, "$1-$2"),

        1: (value) =>
            value
                .slice(0, 11)
                .replace(/(\d{2})(\d)/, "($1) $2")
                .replace(/(\d{5})(\d)/, "$1-$2"),

        2: (value) =>
            value
                .slice(0, 8)
                .replace(/(\d{2})(\d)/, "$1/$2")
                .replace(/(\d{2})(\d)/, "$1/$2"),
        3: (value) => {
            const numericValue = value.replace(/[^\d]/g, "");
            const amount = parseFloat(numericValue) / 100;

            if (!numericValue) {
                return "R$ 0,00";
            }

            return new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            }).format(amount);
        },
    };

    const applyMask = (inputValue) => {
        const unformatted = inputValue.replace(/\D/g, "");
        return formatFieldValue[inputCode](unformatted);
    };

    const handleChange = (e) => {
        const formattedValue = inputCode ? applyMask(e.target.value) : e.target.value;

        inputOnChange &&
            inputOnChange({
                target: {
                    name: inputName,
                    value: formattedValue
                },
            });
    };

    return (
        <div className={clsx("custom-field", `${inputSize}`)}>
            <div className="field">
                {inputType === "select" ? (
                    <select
                        data-field={inputLabel}
                        className="custom-input"
                        id={inputId}
                        name={inputName}
                        value={inputValue}
                        onChange={handleChange}
                    >
                        <option value="">Selecione uma opção</option>
                        {selectOptions?.map((option, index) => (
                            <option key={index} value={option.type} duration={option.duration}>
                                {option.type}
                            </option>
                        ))}
                    </select>
                ) : (
                    <input
                        data-field={inputLabel}
                        className="custom-input"
                        id={inputId}
                        name={inputName}
                        type={inputType}
                        maxLength={inputMaxLength}
                        placeholder={inputPlaceholder}
                        value={inputValue}
                        onChange={handleChange}
                        min={inputMinDate ? generateTodayDate() : undefined}
                    />
                )}
                <label htmlFor={inputId}>{inputLabel}</label>
            </div>

            <div className="field-feedback">
                {feedbackMessage && (
                    <div className={clsx("feedback-message", feedbackType)}>
                        <span>{feedbackIcon}</span>
                        {feedbackMessage}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormField;
// Import dependencies
import { axiosForRequest } from './axios.config.js';

// Function for get list services
export const getListService = async () => {
    try {
        const response = await axiosForRequest.get('/list-services', {})

        return response.data;
    } catch (error) {
        throw error;
    }
};
// Import React
import React from 'react';

// Import CSS
import './App.css';

// Import context
import { SchedulingProvider } from './context/SchedulingContext';

// Import pages
import { Outlet } from "react-router-dom";

// Import components
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
    return (
        <div className="App">
            <ScrollToTop />

            <SchedulingProvider>
                <Outlet />
            </SchedulingProvider>
        </div>
    );
}

export default App;

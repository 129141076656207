// Import react
import React from 'react';

// Import CSS
import './aboutStep.scss'

const AboutStep = ({ data }) => {
    return (
        <span className="about-step">
            {data.map((text, index) => (
                <p key={index}>{text}</p>
            ))}
        </span>
    )
};

export default AboutStep;

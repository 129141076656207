export const SERVICES = [
    {
        "id": "083902645-d128-4c3f-957d-e3ed86fd83f8",
        "image": "/images/type_1.png",
        "type": "Esmaltação em Gel",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "value": "139.90",
        "duration": 10
    },
    {
        "id": "083902642-d128-4c3f-957d-e3ed86fd83f8",
        "tag": "new",
        "image": "/images/type_2.png",
        "type": "SPA dos Pés",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "value": "459.00",
        "duration": 10
    },
    {
        "id": "08390264gf-d128-4c3f-957d-e3ed86fd83f8",
        "tag": "promo",
        "image": "/images/type_3.png",
        "type": "Esmaltação em Gel dos Pés",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "value": "9.90",
        "duration": 10
    },
    {
        "id": "08390264hfd-d128-4c3f-957d-e3ed86fd83f8",
        "image": "/images/type_1.png",
        "type": "Esmaltação em Gel",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "value": "139.90",
        "duration": 10
    },
    {
        "id": "08390264-d1sdf8-4c3f-957d-e3ed86fd83f8",
        "image": "/images/type_1.png",
        "type": "Esmaltação em Gel",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "value": "139.90",
        "duration": 10
    },
    {
        "id": "08390264-d1dsfh28-4c3f-957d-e3ed86fd83f8",
        "tag": "promo",
        "image": "/images/type_3.png",
        "type": "Esmaltação em Gel dos Pés",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "value": "9.90",
        "duration": 10
    },
    {
        "id": "08390264-d128-4c3fjds-957d-e3ed86fd83f8",
        "image": "/images/type_1.png",
        "type": "Esmaltação em Gel",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "value": "139.90",
        "duration": 10
    },
    {
        "id": "08390264-d128-4c3f-9sdfh57d-e3ed86fd83f8",
        "image": "/images/type_1.png",
        "type": "Esmaltação em Gel",
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "value": "139.90",
        "duration": 10
    }
]
// Import react
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Lottie from 'lottie-react';
import Player from 'lottie-react';
import nails from '../animations/loading-nails.json';

// Import components
import FormField from '../components/FormField/FormField';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import ModalOfActionOrFeedback from '../components/ModalOfActionOrFeedback/ModalOfActionOrFeedback';

// Import SVG icons
import { Error, IconCreditCard, Success } from '../icons/Circle';
import { FeedbackError, FeedbackSuccess } from '../icons/Feedback';

// Import services
import { axiosForRequest } from '../services/axios.config';

// Import hooks
import { useListService } from '../hooks/ListService';

// Import functions and validators
import { generateEndTime } from '../functions/generates';
import { convertToReal, formatDateToBrazilian, formatFullName } from '../functions/converts';
import { validateScheduleFields } from '../validators/schedule';

// Import messages
import { ServicesMessages } from '../messages/servicesMessages';

import { IconNew, IconNewSchedule, IconEditSchedule, IconPromo, IconPixPayment, IconCardPayment, IconOptionSelected } from '../icons/Common';
import { SERVICES } from '../data/services';
import AboutStep from '../components/AboutStep/AboutStep';
import { AboutSteps } from '../data/aboutSteps';
import Onboarding from '../pages/Onboarding/Onboarding';
import { useSchedulingOptions } from '../context/SchedulingContext';

function Home() {
    // Defines constants to be used
    const navigate = useNavigate();
    const { ourServices } = useListService()

    // Treatment for component states
    const [schedulingOld, setSchedulingOld] = useState({
        aboutScheduling: {
            name: '',
            phone: '',
            duration: 60,
            startTime: '',
            typeOfService: 'SPA dos Pés',
            dateOfService: '',
            formattedDateOfService: ''
        },
        errors: {
            name: '',
            phone: '',
            typeOfService: '',
            dateOfService: '',
            startTime: ''
        },
        status: {
            isError: {
                status: false,
                message: ''
            },
            isLoading: {
                status: false,
                message: ''
            },
            isSuccess: {
                status: false,
                message: ''
            },
            isSoldOut: {
                status: false
            }
        }
    })

    // Treatment for component states
    const [searchTimes, setSearchTimes] = useState({
        availableTimes: [],
        status: {
            isLoading: false,
            isError: {
                status: false,
                message: ''
            },
            isSuccess: {
                status: false,
                message: ''
            }
        }
    })

    // Actions according to user behavior
    const handleSchedulingValidation = (event) => {
        const { name, value } = event.target;

        setSchedulingOld((prev) => {
            const errorMessage = validateScheduleFields(name, value);

            const updatedScheduling = { ...prev.aboutScheduling, [name]: value };
            const updatedErrors = { ...prev.errors, [name]: errorMessage };

            return {
                ...prev,
                aboutScheduling: updatedScheduling,
                errors: updatedErrors,
            };
        });

        // const serviceSelected = ourServices.data.types.find(
        //     (service) => service.type === value
        // );

        // if (serviceSelected) {
        //     setSchedulingOld((prev) => ({
        //         ...prev,
        //         aboutScheduling: {
        //             ...prev.aboutScheduling,
        //             duration: serviceSelected.duration,
        //         },
        //     }));
        // }

        if (name === "dateOfService") {
            setSearchTimes((prev) => ({
                ...prev,
                availableTimes: []
            }));

            setSchedulingOld((prev) => ({
                ...prev,
                aboutAppointment: {
                    ...prev.aboutAppointment,
                    startTime: ''
                },
                status: {
                    ...prev.status,
                    isSoldOut: {
                        status: false
                    }
                }
            }));
        }
    };

    const handleTimeChange = (event) => {
        setSchedulingOld((prev) => ({
            ...prev,
            aboutScheduling: {
                ...prev.aboutScheduling,
                startTime: event.target.value
            }
        }))
    };

    const handleSearchTimes = async (event) => {
        event.preventDefault();

        setSearchTimes((prev) => ({
            status: {
                ...prev.status,
                isLoading: true
            }
        }))

        // const formError = {
        //     typeOfService: validateScheduleFields('typeOfService', schedulingOld.aboutScheduling.typeOfService),
        //     dateOfService: validateScheduleFields('dateOfService', schedulingOld.aboutScheduling.dateOfService),
        // }

        // if (formError.typeOfService || formError.dateOfService) {
        //     setSchedulingOld((prev) => ({
        //         ...prev,
        //         errors: {
        //             ...prev.errors,
        //             typeOfService: formError.typeOfService,
        //             dateOfService: formError.dateOfService,
        //         },
        //     }));

        //     setSearchTimes((prev) => ({
        //         status: {
        //             ...prev.status,
        //             isLoading: false
        //         }
        //     }))

        //     return;
        // }

        try {
            const response = await axiosForRequest.post('/search-available-times', {
                schedule: {
                    date: schedulingOld.aboutScheduling.dateOfService,
                    duration: schedulingOld.aboutScheduling.duration
                }
            })

            if (response.data.availableTimes && response.data.availableTimes.length === 0) {
                setSchedulingOld((prev) => ({
                    ...prev,
                    aboutScheduling: {
                        ...prev.aboutScheduling,
                        formattedDateOfService: formatDateToBrazilian(schedulingOld.aboutScheduling.dateOfService)
                    },
                    status: {
                        ...prev.status,
                        isSoldOut: {
                            status: true
                        }
                    }
                }))
            }

            setSearchTimes((prev) => ({
                ...prev,
                availableTimes: response.data.availableTimes,
                status: {
                    ...prev.status,
                    isLoading: false
                }
            }))

        } catch (error) {
            const message = error.response?.data?.message || ServicesMessages.GENERIC.INTERNAL_ERROR;

            setSearchTimes((prev) => ({
                status: {
                    ...prev.status,
                    isLoading: false,
                    isError: {
                        status: true,
                        message: message
                    }
                }
            }))
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Sets the loading status of the information
        setSchedulingOld((prev) => ({
            ...prev,
            status: {
                ...prev.status,
                isLoading: {
                    status: true
                }
            }
        }));

        const formError = {
            name: validateScheduleFields('name', schedulingOld.aboutScheduling.name),
            phone: validateScheduleFields('phone', schedulingOld.aboutScheduling.phone),
            typeOfService: validateScheduleFields('typeOfService', schedulingOld.aboutScheduling.typeOfService),
            dateOfService: validateScheduleFields('dateOfService', schedulingOld.aboutScheduling.dateOfService),
            startTime: validateScheduleFields('startTime', schedulingOld.aboutScheduling.startTime),
        }

        if (formError.name || formError.phone || formError.typeOfService || formError.dateOfService) {
            setSchedulingOld((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: {
                        ...prev.isLoading,
                        status: false
                    },
                },
                errors: {
                    ...prev.errors,
                    name: formError.name,
                    phone: formError.phone,
                    typeOfService: formError.typeOfService,
                    dateOfService: formError.dateOfService
                },
            }));

            return;
        }

        if (formError.startTime) {
            setSchedulingOld((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: {
                        ...prev.isLoading,
                        status: false
                    }
                },
                errors: {
                    ...prev.errors,
                    startTime: formError.startTime
                }
            }));

            setSearchTimes((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isError: {
                        status: true,
                        message: formError.startTime
                    }
                }
            }));

            return;
        }

        try {
            await axiosForRequest.post('/schedule-appointment', {
                customer: {
                    name: schedulingOld.aboutScheduling.name,
                    phone: schedulingOld.aboutScheduling.phone,
                    service: schedulingOld.aboutScheduling.typeOfService
                },
                schedule: {
                    date: schedulingOld.aboutScheduling.dateOfService,
                    startTime: schedulingOld.aboutScheduling.startTime,
                    endTime: generateEndTime(schedulingOld.aboutScheduling.startTime, schedulingOld.aboutScheduling.duration)
                }
            })

            setSchedulingOld((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: {
                        ...prev.isLoading,
                        status: false
                    },
                    isSuccess: {
                        status: true,
                        message: 'Agendamento realizado com sucesso!'
                    }
                }
            }));

            setSearchTimes((prev) => ({
                ...prev,
                availableTimes: []
            }));
        } catch (error) {
            const message = error.response?.data?.message || ServicesMessages.GENERIC.INTERNAL_ERROR;

            setSchedulingOld((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: {
                        ...prev.isLoading,
                        status: false
                    },
                    isError: {
                        status: true,
                        message: message
                    }
                }
            }));
        }
    }

    // Closes the feedback modal
    const closeModal = () => {
        setSchedulingOld((prev) => ({
            ...prev,
            aboutScheduling: {
                name: '',
                phone: '',
                typeOfService: '',
                dateOfService: ''
            },
            status: {
                ...prev.status,
                isSuccess: {
                    ...prev.isSuccess,
                    status: false
                },
                isError: {
                    ...prev.isError,
                    status: false
                }
            }
        }));
    };

    const closeModalToSearchTimes = () => {
        setSearchTimes((prev) => ({
            ...prev,
            status: {
                ...prev.status,
                isError: {
                    ...prev.isError,
                    status: false
                }
            }
        }));
    }

    const schedule = {
        customer: {
            name: 'batata',
            phone: ''
        }
    }

    // Estado para armazenar as opções selecionadas
    const [selectedOptions, setSelectedOptions] = useState([]);

    // Função para lidar com a alteração nas seleções
    const handleOptionClick = (option) => {
        if (selectedOptions.includes(option)) {
            // Se a opção já está selecionada, remove-a
            setSelectedOptions(selectedOptions.filter((item) => item !== option));
        } else {
            // Caso contrário, adiciona a opção
            setSelectedOptions([...selectedOptions, option]);
        }
    };

    const handleSelection = (optionType) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(optionType)
                ? prevSelected.filter((type) => type !== optionType) // Remove se já está selecionado
                : [...prevSelected, optionType] // Adiciona se não está selecionado
        );
    };

    // Treatment for steps states
    const [steps, setSteps] = useState({
        currentStep: 0,
        nextStep: 0,
        0: {
            optionsForProgress: {
                newSchedule: false,
                editSchedule: false
            }
        },
        5: {
            optionsForProgress: {
                pixPayment: false,
                cardPayment: false
            }
        }
    })

    // Treatment for steps errors states
    const [stepsErrors, setStepsErrors] = useState({
        noOptionsSelected: {
            status: false,
            message: 'Para prosseguir com o agendamento, é necessário selecionar ao mínimo uma opção.'
        }
    })

    // Handle for scheduling progress
    const handleChangeStep = (event, step) => {
        event.preventDefault();

        // Define the field to validate
        const validationMap = {
            0: {
                type: 'option',
                fields: ['newSchedule', 'editSchedule']
            },
            1: {
                type: 'input',
                fields: ['name', 'phone']
            }
        };

        const { type, fields } = validationMap[steps.currentStep];

        // Handlers for validation by type
        const validationHandlers = {
            option: () => {
                const options = steps[steps.currentStep].optionsForProgress;
                const isOptionSelected = Object.values(options).some((value) => value);

                if (!isOptionSelected) {
                    setStepsErrors((prev) => ({
                        ...prev,
                        noOptionsSelected: {
                            ...prev.noOptionsSelected,
                            status: true,
                        },
                    }));

                    return false;
                }

                return true;
            },
            input: () => {
                const formErrors = {};

                fields.forEach((field) => {
                    const error = validateScheduleFields(
                        field,
                        schedulingOld.aboutScheduling[field]
                    );

                    if (error) {
                        formErrors[field] = error;
                    }
                });

                if (Object.keys(formErrors).length > 0) {
                    setSchedulingOld((prev) => ({
                        ...prev,
                        errors: {
                            ...prev.errors,
                            ...formErrors,
                        },
                    }));

                    return false;
                }

                return true;
            }
        };

        // Execute validation for the current step type
        const isValid = validationHandlers[type]();
        if (!isValid) return;

        // Advance if validation passes
        setSteps((prev) => ({
            ...prev,
            currentStep: step,
        }));
    };

    // Handle for scheduling return
    const handleReturnStep = (event, step) => {
        event.preventDefault();

        // Set return step
        const returnStep = {
            1: () => {
                setSchedulingOld((prev) => ({
                    ...prev,
                    aboutScheduling: {
                        ...prev.aboutScheduling,
                        name: '',
                        phone: ''
                    },
                    errors: {
                        ...prev.errors,
                        name: '',
                        phone: ''
                    },
                }));
            },
            2: () => {
                console.log('n faz nada')
            }
        }

        // Execute cleanup to return
        returnStep[steps.currentStep]()

        // Advance if validation passes
        setSteps((prev) => ({
            ...prev,
            currentStep: step,
        }));
    }

    // Handle for button actions with options to progress
    const handleOptionForProgressClick = (option) => {
        // Set next step
        const nextStep = {
            newSchedule: () => {
                setSteps((prev) => ({
                    ...prev,
                    nextStep: 1,
                }));
            },
            editSchedule: () => {
                setSteps((prev) => ({
                    ...prev,
                    nextStep: 100,
                }));
            }
        }

        // Execute to set next step
        nextStep[option]()

        setSteps((prevSteps) => {
            const currentStep = prevSteps.currentStep;

            return {
                ...prevSteps,
                [currentStep]: {
                    ...prevSteps[currentStep],
                    optionsForProgress: {
                        [option]: !prevSteps[currentStep].optionsForProgress[option]
                    },
                },
            };
        });

        // setwScheduling((prev) => ({
        //     ...prev,
        //     customer: {
        //         ...prev.cusotomer,
        //         name: option
        //     }
        // }));
    };

    const isSelected = (option) =>
        steps[steps.currentStep].optionsForProgress[option];











    // Defines the context
    const { scheduling, setScheduling, feedback, closeFeedback } = useSchedulingOptions()

    return (
        <>
            <section id="app-scheduling" className="sign">
                <section className="content">
                    <div className="logo" alt="logo" />

                    {scheduling.steps.current === 0 && (
                        <Onboarding />
                    )}

                    {scheduling.steps.current === 1 && (
                        <section className="step-content">
                            <div className="step-body">
                                <span className="about-step">
                                    <p>A partir de agora iremos iniciar o seu agendamento.</p>
                                </span>

                                <form className="scheduling" noValidate>
                                    <div className="scheduling-fields">
                                        <FormField
                                            required
                                            inputId="name"
                                            inputName="name"
                                            inputSize="full-size"
                                            inputType="text"
                                            inputLabel="Qual o seu nome?"
                                            inputPlaceholder="Preencha o seu nome completo"
                                            inputValue={schedulingOld.aboutScheduling.name}
                                            inputOnChange={handleSchedulingValidation}
                                            feedbackType={schedulingOld.errors.name ? "error" : "success"}
                                            feedbackMessage={schedulingOld.errors.name}
                                            feedbackIcon={schedulingOld.errors.name ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                        />

                                        <FormField
                                            inputCode={1}
                                            inputId="phone"
                                            inputName="phone"
                                            inputSize="full-size"
                                            inputType="text"
                                            inputLabel="E o seu telefone?"
                                            inputPlaceholder="Dê preferência para o seu whatsapp"
                                            inputValue={schedulingOld.aboutScheduling.phone}
                                            inputOnChange={handleSchedulingValidation}
                                            feedbackType={schedulingOld.errors.phone ? "error" : "success"}
                                            feedbackMessage={schedulingOld.errors.phone}
                                            feedbackIcon={schedulingOld.errors.phone ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                                        />
                                    </div>
                                </form>
                            </div>

                            <div className="step-footer">
                                <div className="step-buttons">
                                    <button className="btn btn-default" onClick={(e) => handleChangeStep(e, 2)}>Avançar</button>
                                    <button className="btn btn-small-default" onClick={(e) => handleReturnStep(e, 0)}>Retornar</button>
                                </div>
                            </div>
                        </section>
                    )}

                    {scheduling.steps.current === 2 && (
                        <section className="step-content">
                            <div className="step-body">
                                <span className="about-step">
                                    <p>{formatFullName(schedulingOld.aboutScheduling.name)}, agora iremos selecionar os serviços. Quais você deseja agendar?</p>
                                </span>

                                <form className="scheduling" onSubmit={handleSubmit} noValidate>
                                    <div className="scheduling-fields">
                                        {SERVICES.map((option, index) => (
                                            <div className="custom-service-option" key={index}>
                                                <div className="option-body">
                                                    <input
                                                        className="custom-checkbox"
                                                        type="checkbox"
                                                        id={`typeOfService-${index}`}
                                                        name="typeOfService"
                                                        value={option.id}
                                                        onChange={() => handleSelection(option.id)}
                                                    />
                                                    <label htmlFor={`typeOfService-${index}`} className={`custom-option ${option.tag ? option.tag : ""}`}>
                                                        <div className={`option-image ${selectedOptions.includes(option.id) ? "selected" : ""}`}>
                                                            {selectedOptions.includes(option.id) && (
                                                                <span className="option-image-selected">
                                                                    <IconOptionSelected width={'45px'} height={'45px'} />
                                                                </span>
                                                            )}

                                                            <img className="option-image" src={option.image} alt={option.type} />
                                                        </div>
                                                        <div className="option-data">
                                                            <div className="option-header">
                                                                <h2 className="option-title">{option.type}</h2>
                                                                <h4 className="option-description">{option.description}</h4>
                                                            </div>

                                                            <div className="option-footer">
                                                                <h2 className="option-price">{convertToReal(option.value)}</h2>

                                                                {option.tag === 'new' ? (
                                                                    <span className="option-tag new">
                                                                        Novidade
                                                                        <IconNew width={'14px'} height={'14px'} />
                                                                    </span>
                                                                ) : (
                                                                    option.tag === 'promo' && (
                                                                        <span className="option-tag promo">
                                                                            Promoção
                                                                            <IconPromo width={'14px'} height={'14px'} />
                                                                        </span>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </form>
                            </div>

                            <div className="step-footer">
                                <div className="step-buttons">
                                    <button className="btn btn-default" onClick={(e) => handleChangeStep(e, 3)}>Avançar</button>
                                    <button className="btn btn-small-default" onClick={(e) => handleReturnStep(e, 1)}>Retornar</button>
                                </div>
                            </div>
                        </section>
                    )}

                    {scheduling.steps.current === 6 && (
                        <section className="step-content">
                            <div className="step-body">
                                <span className="about-step">
                                    <p>Luiz, qual o meio de pagamento?</p>
                                </span>

                                <div className="options-for-progress row">
                                    <button className={`btn btn-default ${isSelected("pixPayment") ? "selected" : ""}`} onClick={() => handleOptionForProgressClick("pixPayment")}>
                                        <IconPixPayment width={"29px"} height={"29px"} />
                                        PIX
                                    </button>

                                    <button className={`btn btn-default ${isSelected("cardPayment") ? "selected" : ""}`} onClick={() => handleOptionForProgressClick("cardPayment")}>
                                        <IconCardPayment width={"29px"} height={"29px"} />
                                        Cartão de Crédito
                                        <p>acréscimo de 4.99%</p>
                                    </button>
                                </div>
                            </div>

                            <div className="step-footer">
                                <div className="step-buttons">
                                    <button className="btn btn-default" onClick={(e) => handleChangeStep(e, 1)}>AVANÇAR</button>
                                </div>
                            </div>
                        </section>
                    )}

                    {scheduling.steps.current === 100 && (
                        <section className="step-content">
                            <div className="step-body">
                                <span className="about-step">
                                    <p>Tela de edição de agendamento</p>
                                </span>
                            </div>

                            <div className="step-footer">
                                <div className="step-buttons">
                                    <button className="btn btn-default" onClick={(e) => handleChangeStep(e, 1)}>AVANÇAR</button>
                                </div>
                            </div>
                        </section>
                    )}
                </section>
            </section>

            <ModalOfActionOrFeedback
                conditionToBeVisible={feedback.status}
                typeOfModal={{ type: "feedback", colorFor: feedback.type }}

                data={{
                    icon: <FeedbackError width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: feedback.message
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeFeedback }}
            />

            <ModalOfActionOrFeedback
                conditionToBeVisible={schedulingOld.status.isSuccess.status}
                typeOfModal={{ type: "feedback", colorFor: "success" }}

                data={{
                    icon: <FeedbackSuccess width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: schedulingOld.status.isSuccess.message
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModal }}
            />

            <ModalOfActionOrFeedback
                conditionToBeVisible={searchTimes.status.isError.status}
                typeOfModal={{ type: "feedback", colorFor: "error" }}

                data={{
                    icon: <FeedbackError width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: searchTimes.status.isError.message
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeModalToSearchTimes }}
            />
        </>
    );
}

export default Home;
// Import react
import React from 'react';

// Import icons
import { IconNewSchedule, IconEditSchedule } from '../../icons/Common';

// Import components
import AboutStep from '../../components/AboutStep/AboutStep';

// Import data and context
import { AboutSteps } from '../../data/aboutSteps';
import { useSchedulingOptions } from '../../context/SchedulingContext';

function Onboarding() {
    // Defines the context
    const { scheduling, setScheduling, setNextStep, setFeedback } = useSchedulingOptions()

    // Defines the data to be used
    const nextStepMapping = {
        schedule: AboutSteps.registerSchedulingData.step,
        reschedule: AboutSteps.editSchedulingData.step,
    };

    // Defines the available options to be used
    const availableOptionsForProgress = {
        schedule: {
            icon: <IconNewSchedule width={"29px"} height={"29px"} />,
            text: "Agendar novo horário"
        },
        reschedule: {
            icon: <IconEditSchedule width={"29px"} height={"29px"} />,
            text: "Alterar meu horário agendado",
            info: "É permitido a alteração até 48 horas antes do agendamento"
        }
    }

    // Handle for button actions with options to progress
    const handleOptionForProgressClick = (option) => {
        setScheduling((prev) => ({
            ...prev,
            steps: {
                ...prev.steps,
                next: nextStepMapping[option],
            },
        }));
    };

    // Handle to check if an option is selected
    const handleChangeStepWithValidation = (e) => {
        if (scheduling.steps?.next == 0) {
            setFeedback((prev) => ({
                ...prev,
                status: true,
                type: "error",
                message: "Para prosseguir com o agendamento, é necessário selecionar ao mínimo uma opção."
            }));

            return;
        }

        // Sets the next step if selected
        setNextStep(e, scheduling.steps.next);
    };

    // Checks if the next step configured in the status corresponds to the option
    const isSelected = (option) => scheduling.steps.next === nextStepMapping[option];

    return (
        <>
            <section className="step-content">
                <div className="step-body">
                    <AboutStep data={AboutSteps.onboarding?.about} />

                    <div className="options-for-progress column">
                        {Object.entries(availableOptionsForProgress).map(([optionKey, optionValue]) => (
                            <button
                                key={optionKey}
                                name={optionKey}
                                className={`btn btn-default ${isSelected(optionKey) ? "selected" : ""}`}
                                onClick={() => handleOptionForProgressClick(optionKey)}
                            >
                                {optionValue.icon}
                                {optionValue.text}
                                {optionValue.info && <p>{optionValue.info}</p>}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="step-footer">
                    <div className="step-buttons">
                        <button className="btn btn-default" onClick={handleChangeStepWithValidation}>Avançar</button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Onboarding;
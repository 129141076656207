export const AboutSteps = {
    onboarding: {
        step: 0,
        about: [
            "Olá! Seja bem-vinda(o) ao espaço exclusivo de agendamentos da Sthepanie Nail Designer.",
            "Para iniciarmos, o que você deseja fazer hoje?"
        ]
    },
    registerSchedulingData: {
        step: 1,
        about: []
    },
    editSchedulingData: {
        step: 100,
        about: []
    }
};